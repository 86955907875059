(function () {
  'use strict';

  angular
  .module('neo.services')
  .factory('ActionResendRegEmail', ActionResendRegEmail);

  function ActionResendRegEmail($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/users/resendemail');
  }
}());
